import _isEmpty from "lodash/isEmpty";
import _defaults from "lodash/defaults";
import _assign from "lodash/assign";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

/*eslint no-magic-numbers: ["error", { "ignore": [-0.5, 0.5, 0, 1, 2] }]*/
import React from "react";
import PropTypes from "prop-types";
import VictoryPortal from "../victory-portal/victory-portal";
import Rect from "../victory-primitives/rect";
import CustomPropTypes from "../victory-util/prop-types";
import Helpers from "../victory-util/helpers";
import LabelHelpers from "../victory-util/label-helpers";
import Style from "../victory-util/style";
import Log from "../victory-util/log";
import TextSize from "../victory-util/textsize";
import TSpan from "../victory-primitives/tspan";
import Text from "../victory-primitives/text";
var defaultStyles = {
  fill: "#252525",
  fontSize: 14,
  fontFamily: "'Gill Sans', 'Gill Sans MT', 'Ser­avek', 'Trebuchet MS', sans-serif",
  stroke: "transparent"
};

var getPosition = function (props, dimension) {
  if (!props.datum) {
    return 0;
  }

  var scaledPoint = Helpers.scalePoint(props, props.datum);
  return scaledPoint[dimension];
};

var getFontSize = function (style) {
  var baseSize = style && style.fontSize;

  if (typeof baseSize === "number") {
    return baseSize;
  } else if (baseSize === undefined || baseSize === null) {
    return defaultStyles.fontSize;
  } else if (typeof baseSize === "string") {
    var fontSize = +baseSize.replace("px", "");

    if (!isNaN(fontSize)) {
      return fontSize;
    } else {
      Log.warn("fontSize should be expressed as a number of pixels");
      return defaultStyles.fontSize;
    }
  }

  return defaultStyles.fontSize;
};

var getSingleValue = function (prop) {
  var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return Array.isArray(prop) ? prop[index] || prop[0] : prop;
};

var useMultiLineBackgrounds = function (props) {
  var backgroundStyle = props.backgroundStyle,
      backgroundPadding = props.backgroundPadding;
  return Array.isArray(backgroundStyle) && !_isEmpty(backgroundStyle) || Array.isArray(backgroundPadding) && !_isEmpty(backgroundPadding);
};

var getStyles = function (style, props) {
  if (props.disableInlineStyles) {
    var baseStyles = Helpers.evaluateStyle(style, props);
    return {
      // Font size is necessary to calculate the y position of the label
      fontSize: getFontSize(baseStyles)
    };
  }

  var getSingleStyle = function (s) {
    s = s ? _defaults({}, s, defaultStyles) : defaultStyles;
    var baseStyles = Helpers.evaluateStyle(s, props);
    return _assign({}, baseStyles, {
      fontSize: getFontSize(baseStyles)
    });
  };

  return Array.isArray(style) && !_isEmpty(style) ? style.map(function (s) {
    return getSingleStyle(s);
  }) : getSingleStyle(style);
};

var getBackgroundStyles = function (style, props) {
  if (!style) {
    return undefined;
  }

  return Array.isArray(style) && !_isEmpty(style) ? style.map(function (s) {
    return Helpers.evaluateStyle(s, props);
  }) : Helpers.evaluateStyle(style, props);
};

var getBackgroundPadding = function (props) {
  if (props.backgroundPadding && Array.isArray(props.backgroundPadding)) {
    return props.backgroundPadding.map(function (backgroundPadding) {
      var padding = Helpers.evaluateProp(backgroundPadding, props);
      return Helpers.getPadding({
        padding: padding
      });
    });
  } else {
    var padding = Helpers.evaluateProp(props.backgroundPadding, props);
    return Helpers.getPadding({
      padding: padding
    });
  }
};

var getLineHeight = function (props) {
  var lineHeight = Helpers.evaluateProp(props.lineHeight, props);

  if (Array.isArray(lineHeight)) {
    return _isEmpty(lineHeight) ? [1] : lineHeight;
  } else {
    return lineHeight;
  }
};

var getContent = function (text, props) {
  if (text === undefined || text === null) {
    return undefined;
  }

  if (Array.isArray(text)) {
    return text.map(function (line) {
      return Helpers.evaluateProp(line, props);
    });
  }

  var child = Helpers.evaluateProp(text, props);

  if (child === undefined || child === null) {
    return undefined;
  }

  return Array.isArray(child) ? child : "".concat(child).split("\n");
};

var getDy = function (props, verticalAnchor, lineHeight) {
  var dy = props.dy ? Helpers.evaluateProp(props.dy, props) : 0;
  var length = props.inline ? 1 : props.text.length;
  var capHeight = Helpers.evaluateProp(props.capHeight, props);
  var anchor = verticalAnchor ? Helpers.evaluateProp(verticalAnchor, props) : "middle";

  var fontSizes = _toConsumableArray(Array(length).keys()).map(function (i) {
    return getSingleValue(props.style, i).fontSize;
  });

  var lineHeights = _toConsumableArray(Array(length).keys()).map(function (i) {
    return getSingleValue(lineHeight, i);
  });

  if (anchor === "start") {
    return dy + (capHeight / 2 + lineHeights[0] / 2) * fontSizes[0];
  } else if (props.inline) {
    return anchor === "end" ? dy + (capHeight / 2 - lineHeights[0] / 2) * fontSizes[0] : dy + capHeight / 2 * fontSizes[0];
  } else if (length === 1) {
    return anchor === "end" ? dy + (capHeight / 2 + (0.5 - length) * lineHeights[0]) * fontSizes[0] : dy + (capHeight / 2 + (0.5 - length / 2) * lineHeights[0]) * fontSizes[0];
  } else {
    var allHeights = _toConsumableArray(Array(length).keys()).reduce(function (memo, i) {
      return memo + (capHeight / 2 + (0.5 - length) * lineHeights[i]) * fontSizes[i] / length;
    }, 0);

    return anchor === "end" ? dy + allHeights : dy + allHeights / 2 + capHeight / 2 * lineHeights[length - 1] * fontSizes[length - 1];
  }
};

var getTransform = function (props, x, y) {
  var polar = props.polar;
  var style = getSingleValue(props.style);
  var defaultAngle = polar ? LabelHelpers.getPolarAngle(props) : 0;
  var baseAngle = style.angle === undefined ? Helpers.evaluateProp(props.angle, props) : style.angle;
  var angle = baseAngle === undefined ? defaultAngle : baseAngle;
  var transform = props.transform || style.transform;
  var transformPart = transform && Helpers.evaluateProp(transform, props);
  var rotatePart = angle && {
    rotate: [angle, x, y]
  };
  return transformPart || angle ? Style.toTransformString(transformPart, rotatePart) : undefined;
};

var getXCoordinate = function (calculatedProps, labelSizeWidth) {
  var direction = calculatedProps.direction,
      textAnchor = calculatedProps.textAnchor,
      x = calculatedProps.x,
      dx = calculatedProps.dx;

  if (direction === "rtl") {
    return x - labelSizeWidth;
  }

  switch (textAnchor) {
    case "middle":
      return Math.round(x - labelSizeWidth / 2);

    case "end":
      return Math.round(x - labelSizeWidth);

    default:
      // start
      return x + (dx || 0);
  }
};

var getYCoordinate = function (calculatedProps, textHeight) {
  var verticalAnchor = calculatedProps.verticalAnchor,
      y = calculatedProps.y,
      _calculatedProps$orig = calculatedProps.originalDy,
      originalDy = _calculatedProps$orig === void 0 ? 0 : _calculatedProps$orig;
  var offset = y + originalDy;

  switch (verticalAnchor) {
    case "start":
      return Math.floor(offset);

    case "end":
      return Math.ceil(offset - textHeight);

    default:
      // middle
      return Math.floor(offset - textHeight / 2);
  }
};

var getFullBackground = function (calculatedProps, tspanValues) {
  var _calculatedProps$dx = calculatedProps.dx,
      dx = _calculatedProps$dx === void 0 ? 0 : _calculatedProps$dx,
      transform = calculatedProps.transform,
      backgroundComponent = calculatedProps.backgroundComponent,
      backgroundStyle = calculatedProps.backgroundStyle,
      inline = calculatedProps.inline,
      backgroundPadding = calculatedProps.backgroundPadding,
      capHeight = calculatedProps.capHeight;
  var textSizes = tspanValues.map(function (tspan) {
    return tspan.textSize;
  });
  var height = inline ? Math.max.apply(Math, _toConsumableArray(textSizes.map(function (size) {
    return size.height;
  }))) : textSizes.reduce(function (memo, size, i) {
    var capHeightAdjustment = i ? 0 : capHeight / 2;
    return memo + size.height * (tspanValues[i].lineHeight - capHeightAdjustment);
  }, 0);
  var width = inline ? textSizes.reduce(function (memo, size, index) {
    var offset = index ? dx : 0;
    return memo + size.width + offset;
  }, 0) : Math.max.apply(Math, _toConsumableArray(textSizes.map(function (size) {
    return size.width;
  })));
  var xCoordinate = getXCoordinate(calculatedProps, width);
  var yCoordinate = getYCoordinate(calculatedProps, height);
  var backgroundProps = {
    key: "background",
    height: height + backgroundPadding.top + backgroundPadding.bottom,
    style: backgroundStyle,
    transform: transform,
    width: width + backgroundPadding.left + backgroundPadding.right,
    x: inline ? xCoordinate - backgroundPadding.left : xCoordinate + dx - backgroundPadding.left,
    y: yCoordinate
  };
  return React.cloneElement(backgroundComponent, _defaults({}, backgroundComponent.props, backgroundProps));
};

var getInlineXOffset = function (calculatedProps, textElements, index) {
  var textAnchor = calculatedProps.textAnchor;
  var widths = textElements.map(function (t) {
    return t.widthWithPadding;
  });
  var totalWidth = widths.reduce(function (memo, width) {
    return memo + width;
  }, 0);
  var centerOffset = -totalWidth / 2;

  switch (textAnchor) {
    case "start":
      return widths.reduce(function (memo, width, i) {
        memo = i < index ? memo + width : memo;
        return memo;
      }, 0);

    case "end":
      return widths.reduce(function (memo, width, i) {
        memo = i > index ? memo - width : memo;
        return memo;
      }, 0);

    default:
      // middle
      return widths.reduce(function (memo, width, i) {
        var offsetWidth = i < index ? width : 0;
        memo = i === index ? memo + width / 2 : memo + offsetWidth;
        return memo;
      }, centerOffset);
  }
};

var getChildBackgrounds = function (calculatedProps, tspanValues) {
  var dy = calculatedProps.dy,
      dx = calculatedProps.dx,
      transform = calculatedProps.transform,
      backgroundStyle = calculatedProps.backgroundStyle,
      backgroundPadding = calculatedProps.backgroundPadding,
      backgroundComponent = calculatedProps.backgroundComponent,
      inline = calculatedProps.inline,
      y = calculatedProps.y;
  var textElements = tspanValues.map(function (current, i) {
    var previous = getSingleValue(tspanValues, i - 1);
    var labelSize = current.textSize;
    var totalLineHeight = current.fontSize * current.lineHeight;
    var textHeight = Math.ceil(totalLineHeight);
    var padding = getSingleValue(backgroundPadding, i);
    var prevPadding = getSingleValue(backgroundPadding, i - 1);
    var xOffset = inline ? dx || 0 : 0;
    var childDy = i && !inline ? previous.fontSize * previous.lineHeight + prevPadding.top + prevPadding.bottom : dy - totalLineHeight * 0.5 - (current.fontSize - current.capHeight);
    return {
      textHeight: textHeight,
      labelSize: labelSize,
      heightWithPadding: textHeight + padding.top + padding.bottom,
      widthWithPadding: labelSize.width + padding.left + padding.right + xOffset,
      y: y,
      fontSize: current.fontSize,
      dy: childDy
    };
  });
  return textElements.map(function (textElement, i) {
    var xCoordinate = getXCoordinate(calculatedProps, textElement.labelSize.width);
    var yCoordinate = textElements.slice(0, i + 1).reduce(function (prev, curr) {
      return prev + curr.dy;
    }, y);
    var padding = getSingleValue(backgroundPadding, i);
    var height = textElement.heightWithPadding;
    var xCoord = inline ? getInlineXOffset(calculatedProps, textElements, i) + xCoordinate - padding.left : xCoordinate;
    var yCoord = inline ? getYCoordinate(calculatedProps, height) - padding.top : yCoordinate;
    var backgroundProps = {
      key: "tspan-background-".concat(i),
      height: height,
      style: getSingleValue(backgroundStyle, i),
      width: textElement.widthWithPadding,
      transform: transform,
      x: xCoord - padding.left,
      y: yCoord
    };
    return React.cloneElement(backgroundComponent, _defaults({}, backgroundComponent.props, backgroundProps));
  });
};

var getBackgroundElement = function (calculatedProps, tspanValues) {
  return useMultiLineBackgrounds(calculatedProps) ? getChildBackgrounds(calculatedProps, tspanValues) : getFullBackground(calculatedProps, tspanValues);
};

var calculateSpanDy = function (tspanValues, i, calculatedProps) {
  var current = getSingleValue(tspanValues, i);
  var previous = getSingleValue(tspanValues, i - 1);
  var previousHeight = previous.fontSize * previous.lineHeight;
  var currentHeight = current.fontSize * current.lineHeight;
  var previousCaps = previous.fontSize - previous.capHeight;
  var currentCaps = current.fontSize - current.capHeight;
  var textHeight = previousHeight - previous.fontSize / 2 + current.fontSize / 2 - previousHeight / 2 + currentHeight / 2 - currentCaps / 2 + previousCaps / 2;
  return useMultiLineBackgrounds(calculatedProps) ? textHeight + current.backgroundPadding.top + previous.backgroundPadding.bottom : textHeight;
};

var getTSpanDy = function (tspanValues, calculatedProps, i) {
  var inline = calculatedProps.inline;
  var current = getSingleValue(tspanValues, i);

  if (i && !inline) {
    return calculateSpanDy(tspanValues, i, calculatedProps);
  } else if (inline) {
    return i === 0 ? current.backgroundPadding.top : undefined;
  } else {
    return current.backgroundPadding.top;
  }
};

var evaluateProps = function (props) {
  /* Potential evaluated props are
    1) text
    2) style
    3) everything else
  */
  var text = getContent(props.text, props);
  var style = getStyles(props.style, _assign({}, props, {
    text: text
  }));
  var backgroundStyle = getBackgroundStyles(props.backgroundStyle, _assign({}, props, {
    text: text,
    style: style
  }));
  var backgroundPadding = getBackgroundPadding(_assign({}, props, {
    text: text,
    style: style,
    backgroundStyle: backgroundStyle
  }));
  var id = Helpers.evaluateProp(props.id, props);
  return _assign({}, props, {
    backgroundStyle: backgroundStyle,
    backgroundPadding: backgroundPadding,
    style: style,
    text: text,
    id: id
  });
};

var getCalculatedProps = function (props) {
  var ariaLabel = Helpers.evaluateProp(props.ariaLabel, props);
  var style = getSingleValue(props.style);
  var lineHeight = getLineHeight(props);
  var direction = props.direction ? Helpers.evaluateProp(props.direction, props) : "inherit";
  var textAnchor = props.textAnchor ? Helpers.evaluateProp(props.textAnchor, props) : style.textAnchor || "start";
  var verticalAnchor = props.verticalAnchor ? Helpers.evaluateProp(props.verticalAnchor, props) : style.verticalAnchor || "middle";
  var dx = props.dx ? Helpers.evaluateProp(props.dx, props) : 0;
  var dy = getDy(props, verticalAnchor, lineHeight);
  var x = props.x !== undefined ? props.x : getPosition(props, "x");
  var y = props.y !== undefined ? props.y : getPosition(props, "y");
  var transform = getTransform(props, x, y);
  return _assign({}, props, {
    ariaLabel: ariaLabel,
    lineHeight: lineHeight,
    direction: direction,
    textAnchor: textAnchor,
    verticalAnchor: verticalAnchor,
    dx: dx,
    dy: dy,
    originalDy: props.dy,
    transform: transform,
    x: x,
    y: y
  });
};

var renderLabel = function (calculatedProps, tspanValues) {
  var ariaLabel = calculatedProps.ariaLabel,
      inline = calculatedProps.inline,
      className = calculatedProps.className,
      title = calculatedProps.title,
      events = calculatedProps.events,
      direction = calculatedProps.direction,
      text = calculatedProps.text,
      textAnchor = calculatedProps.textAnchor,
      dx = calculatedProps.dx,
      dy = calculatedProps.dy,
      transform = calculatedProps.transform,
      x = calculatedProps.x,
      y = calculatedProps.y,
      desc = calculatedProps.desc,
      id = calculatedProps.id,
      tabIndex = calculatedProps.tabIndex,
      tspanComponent = calculatedProps.tspanComponent,
      textComponent = calculatedProps.textComponent;

  var textProps = _objectSpread({
    "aria-label": ariaLabel,
    key: "text"
  }, events, {
    direction: direction,
    dx: dx,
    x: x,
    y: y + dy,
    transform: transform,
    className: className,
    title: title,
    desc: Helpers.evaluateProp(desc, calculatedProps),
    tabIndex: Helpers.evaluateProp(tabIndex, calculatedProps),
    id: id
  });

  var tspans = text.map(function (line, i) {
    var currentStyle = tspanValues[i].style;
    var tspanProps = {
      key: "".concat(id, "-key-").concat(i),
      x: !inline ? x : undefined,
      dx: inline ? dx + tspanValues[i].backgroundPadding.left : dx,
      dy: getTSpanDy(tspanValues, calculatedProps, i),
      textAnchor: currentStyle.textAnchor || textAnchor,
      style: currentStyle,
      children: line
    };
    return React.cloneElement(tspanComponent, tspanProps);
  });
  return React.cloneElement(textComponent, textProps, tspans);
};

var VictoryLabel = function (props) {
  props = evaluateProps(props);

  if (props.text === null || props.text === undefined) {
    return null;
  }

  var calculatedProps = getCalculatedProps(props);
  var text = calculatedProps.text,
      style = calculatedProps.style,
      capHeight = calculatedProps.capHeight,
      backgroundPadding = calculatedProps.backgroundPadding,
      lineHeight = calculatedProps.lineHeight;
  var tspanValues = text.map(function (line, i) {
    var currentStyle = getSingleValue(style, i);
    var capHeightPx = TextSize.convertLengthToPixels("".concat(capHeight, "em"), currentStyle.fontSize);
    var currentLineHeight = getSingleValue(lineHeight, i);
    return {
      style: currentStyle,
      fontSize: currentStyle.fontSize || defaultStyles.fontSize,
      capHeight: capHeightPx,
      text: line,
      textSize: TextSize.approximateTextSize(line, currentStyle),
      lineHeight: currentLineHeight,
      backgroundPadding: getSingleValue(backgroundPadding, i)
    };
  });
  var label = renderLabel(calculatedProps, tspanValues);

  if (props.backgroundStyle) {
    var backgroundElement = getBackgroundElement(calculatedProps, tspanValues);
    var children = [backgroundElement, label];
    var backgroundWithLabel = React.cloneElement(props.groupComponent, {}, children);
    return props.renderInPortal ? React.createElement(VictoryPortal, null, backgroundWithLabel) : backgroundWithLabel;
  }

  return props.renderInPortal ? React.createElement(VictoryPortal, null, label) : label;
};

VictoryLabel.displayName = "VictoryLabel";
VictoryLabel.role = "label";
VictoryLabel.defaultStyles = defaultStyles;
VictoryLabel.propTypes = {
  active: PropTypes.bool,
  angle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  backgroundComponent: PropTypes.element,
  backgroundPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  backgroundStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  capHeight: PropTypes.oneOfType([PropTypes.string, CustomPropTypes.nonNegative, PropTypes.func]),
  className: PropTypes.string,
  data: PropTypes.array,
  datum: PropTypes.any,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  direction: PropTypes.oneOf(["rtl", "ltr", "inherit"]),
  dx: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
  dy: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
  events: PropTypes.object,
  groupComponent: PropTypes.element,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool,
  labelPlacement: PropTypes.oneOf(["parallel", "perpendicular", "vertical"]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, CustomPropTypes.nonNegative, PropTypes.func, PropTypes.array]),
  origin: PropTypes.shape({
    x: CustomPropTypes.nonNegative,
    y: CustomPropTypes.nonNegative
  }),
  polar: PropTypes.bool,
  renderInPortal: PropTypes.bool,
  scale: PropTypes.shape({
    x: CustomPropTypes.scale,
    y: CustomPropTypes.scale
  }),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func, PropTypes.array]),
  textAnchor: PropTypes.oneOfType([PropTypes.oneOf(["start", "middle", "end", "inherit"]), PropTypes.func]),
  textComponent: PropTypes.element,
  title: PropTypes.string,
  transform: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  tspanComponent: PropTypes.element,
  verticalAnchor: PropTypes.oneOfType([PropTypes.oneOf(["start", "middle", "end"]), PropTypes.func]),
  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
VictoryLabel.defaultProps = {
  backgroundComponent: React.createElement(Rect, null),
  groupComponent: React.createElement("g", null),
  direction: "inherit",
  textComponent: React.createElement(Text, null),
  tspanComponent: React.createElement(TSpan, null),
  capHeight: 0.71,
  // Magic number from d3.
  lineHeight: 1
};
export default VictoryLabel;